import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Fade from "react-reveal/Fade"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const GameChanger = () => {
  return (
    <>
      <Layout>
        <Seo title="Game Changer for AR and VR" />
        <Fade top>
          <div className="py-10 lg:py-12 bg-dusk"></div>
        </Fade>
        <div className="py-24">
          <div className="xl:pr-96">
            <div className="container mx-auto text-dusk">
              <div>
                <div className="text-5xl font-firs-medium">
                  Game Changer for AR and VR:
                  <div> Smart Textiles</div>
                </div>
                {/* <div className="text-2xl mt-4">
                  Through the lens of wearable technology
                  <div>and smart textiles</div>
                </div> */}
                <div className="mt-4">20th September 2021</div>
              </div>
              <div className=" my-7  py-10">
                {/* <div>
                  <StaticImage
                    alt="Softmatter"
                    src="../../images/news/reinventing/Smart-Homes.jpg"
                  />
                </div> */}
                <div className="my-4">The first stereoscope.</div>
                <div className="mb-4">Arcade machines. And a Sayre Glove.</div>
                <div className="mb-4">
                  We’re connected in ways – we could only imagine before.
                </div>
                <div className="mb-4">
                  Snapchat lenses, Microsoft HoloLens and Google maps AR
                  navigation systems that superimpose direction and details on
                  your display, offering a live view of the road rather than
                  just presenting you with a map.
                </div>
                <div className="mb-4">Are you catching the difference?</div>
                <div className="mb-4">
                  The former represents leading-edge technology in 1838- 1990.
                  The latter is a small sampling of the impressive technologies
                  that are currently part of the larger ecosystem in the AR and
                  VR landscape.
                </div>
                <div className="mb-4">
                  We have come a long way in connecting in ways – we could only
                  imagine before.
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    AR and VR experiences a Renaissance
                  </div>
                  <div className="mb-4">
                    Smart Textiles are finding a home in both virtual reality
                    (VR) and augmented reality (AR) applications. These wearable
                    solutions are some of the hottest multidisciplinary
                    technology trends right now – offering the potential for
                    interaction beyond motion and physical buttons.
                  </div>
                  <div className="mb-4">
                    By integrating technology, various sensors, graphic images,
                    communication, measurement, control multimedia and
                    artificial intelligence into an interactive user experience
                    – you can create experiences unlike any other in computing.
                  </div>
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/news/ar-vr/Augemented-reality.jpg"
                    />
                  </div>
                  <div className="mb-4 mt-4">
                    VR implies a complete immersion experience that shuts out
                    the physical world. VR frequently makes use of behavioral
                    interfaces and computer programs to allow people to interact
                    with one another and with 3D objects in a virtual
                    environment. In contrast, AR adds digital elements to a live
                    view, often using the camera on a smartphone or an AR
                    headset. Simply put, VR is a total virtual experience while
                    AR is a mixture of the real world and virtual overlays.
                  </div>
                  <div className="mb-4 mt-4">
                    While AR can work on existing display screens such as
                    smartphones and other monitors, most associate VR to its
                    bulky head-mounted display (HMD). VR’s mimicking of the
                    human sensory systems is often limited to audio and video
                    channels, and requires boxy and rigid devices – limiting
                    it’s potential to gaming, job training and 360-degree video
                    content.
                  </div>
                  <div className="mb-4 mt-4">
                    But textiles have gotten smarter.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Softmatter’s Smart Textile Solutions for AR and VR
                  </div>
                  <div className="mb-4">
                    Smart Textiles opens up a whole array of solutions to AR and
                    VR development. By replacing its hard surfaces with smart
                    textiles, flexible conductors for high-speed data transfer –
                    interactive textile may provide suitable platforms for VR/AR
                    applications because of their excellent performance and
                    unique immersive features. They can be programmatically
                    actuated and linked, allowing for the creation of
                    intelligent wearables that respond to external stimuli, or
                    user-designed patterns. Due to its lightweight, handiness,
                    flexibility and comfort it offers low strain even under high
                    deformations.
                  </div>
                  <div className="mb-4">
                    Possibilities range from the creation of wearable VR/AR
                    systems for immersive experiences via motion analysis and
                    feedback, to the utilization of stretchable fabric-based
                    high-speed data cables for 4K video transfer.
                  </div>
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/news/ar-vr/ARVR-1.jpg"
                    />
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Stretchable HSD transfer
                  </div>
                  <div className="mb-4">
                    Stretchable HSD transfer power transfer enables decoupling
                    of XR components. Rather than forcing all of the components
                    (camera, display, processing, and power) on the front of the
                    head, causing imbalance and strain, the stretchable HSD
                    conductors aid distributed computing, allowing power,
                    processing, and other components to be relocated to
                    appropriate locations for optimal balance and comfort for
                    the user.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Interactive Fabric Interfaces
                  </div>
                  <div className="mb-4">
                    Interactive textile devices offer a better experience in
                    emerging VR/AR applications with respect to other sensory
                    interactions. They enable the wearable VR/AR system to be
                    smaller, softer, and more comfortable, which broadens the
                    range of their potential applications and their appeal.
                    Touch textiles in particular can also be used to create
                    interactive fabric surfaces. These surfaces could be used
                    for a variety of applications, from keyboards to automobile
                    interiors.
                  </div>
                  <div className="mb-4">
                    Apart from actuators and electrodes, an important part of
                    textile electronics are sensors to detect stimuli. These
                    sensors can acquire abundant information about the
                    environment as a basis of interaction. Considering the
                    characteristics of textiles, an integrated sensor can be
                    flexible, durable, biocompatible, and lightweight. The
                    sensors can be used in VR/AR systems to measure movement,
                    pressure, temperature, and can be multifunctional. Sensory
                    stimuli provide people with a coherent perception of the
                    world. This, along with other perception devices, transforms
                    perception models in the virtual world into multi-channel
                    stimuli (e.g., tactile, visual, and audio) compatible with
                    VR/AR systems. Textile electronic devices provide an ideal
                    platform for users to interact with visually depicted
                    objects or settings while simultaneously experiencing audio,
                    video, haptic feedback, multimodal sensory inputs, and
                    movement.
                  </div>
                  <div className="mb-4">
                    Various fabrication processes exist to create interactive
                    textile devices. By partnering with our experienced product
                    development team, you can:
                  </div>
                  <div className="mb-4 pl-8">
                    + Design and build from concept to production.
                  </div>
                  <div className="mb-4 pl-8">
                    + Solve unprecedented regulatory challenges while
                    accelerating time to market.
                  </div>
                  <div className="mb-4 pl-8">
                    + Quality commitment of ISO 9001 and 13485 facilities with
                    FDA Class I and II products in execution.
                  </div>
                  <div className="mb-4">
                    Do you want to make technology that prioritizes your user’s
                    experience quality? Connect with Softmatter to get your
                    AR/AR wearable device production underway.
                  </div>

                  <div className="mt-20">
                    <div className="grid sm:grid-cols-2">
                      <div className="">
                        <StaticImage
                          alt="Softmatter"
                          src="../../images/news/Post3.png"
                        />
                        <div className="mb-2 text-sm mt-4 text-salmon font-firs-medium">
                          Evolved from a futuristic accessory to a ubiquitous
                          health and wellness wearable:
                        </div>
                        <div className="text-sm mb-2">
                          Sep 15, 2021 | Health and wellness Wearables,
                          Engineered Knit, Product Engineering
                        </div>
                        <div className="text-sm mb-2">
                          What factors will gear more consumers to be more
                          comfortable in having a virtual health assistant
                          strapped to their wrist?
                        </div>
                        <div className="text-sm mt-2 text-salmon font-firs-medium">
                          <Link to="/news">
                            <span className="hover:underline cursor-pointer">
                              Read More
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default GameChanger
